#Documents {
    margin-top: 10px;
}

#Documents #document-list .document-data {
    margin: 5px;
    display: grid;
    grid-template-rows: 30px 30px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "documentnumber documentnumber documentnumber" "documentname documentdate documentvalue";
}

#Documents #document-list .document-data .document-number {
    grid-area: documentnumber;
    font-weight: bold;
}

#Documents #document-list .document-data .document-name {
    grid-area: documentname;
}

#Documents #document-list .document-data .document-date {
    grid-area: documentdate;
}

#Documents #document-list .document-data .document-value {
    grid-area: documentvalue;
}

@media (max-width: 759px) {
	#Documents #document-list .document-data {
        grid-template-rows: 30px max-content max-content max-content;
        grid-template-columns: 1fr;
        grid-template-areas: "documentnumber" "documentname" "documentdate" "documentvalue";
    }
}