#Customers {
    margin-top: 10px;
}

#Customers #customer-list .customer-data {
    margin: 5px;
    display: grid;
    grid-template-rows: 30px max-content;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "customername customername customername" "customermail customerphone customermobile";
}

#Customers #customer-list .customer-data .customer-name {
    grid-area: customername;
    font-weight: bold;
}

#Customers #customer-list .customer-data .customer-mail {
    grid-area: customermail;
}

#Customers #customer-list .customer-data .customer-phone {
    grid-area: customerphone;
}

#Customers #customer-list .customer-data .customer-mobile {
    grid-area: customermobile;
}

@media (max-width: 759px) {
	#Customers #customer-list .customer-data {
        grid-template-rows: 30px max-content max-content max-content;
        grid-template-columns: 1fr;
        grid-template-areas: "customername" "customermail" "customerphone" "customermobile";
    }
}