.common-search-list {
    display: grid;
    grid-template-rows: 50px 1fr;
    grid-template-areas: "header" "list";
    width: 100%;
    height: 100%;
}

.common-search-list #header {
    grid-area: header;
    display: grid;
    grid-template-columns: minmax(min-content, 1fr) 60px;
    grid-template-areas: "search add";
    border-bottom: 1px solid var(--borderColor);
}

.common-search-list #header #add-button {
    grid-area: add;
    align-self: center;
    justify-self: center;
}

.common-search-list #header #search-input {
    grid-area: search;
    margin: 5px;
    max-width: 500px;
    min-width: 250px;
}

.common-search-list #add-button {
    grid-area: add;
    margin: 5px;
}

.common-search-list #list {
    grid-area: list;
    display: grid;
    width: 100%;
    height: 100%;
    overflow: auto;
    grid-auto-rows: minmax(min-content, max-content);
}