#Document-Details #document-positions .document-position {
    display: grid;
    grid-template-rows: max-content 35px;
    grid-template-columns: 1fr 1fr 1fr 50px;
    grid-template-areas: "description description description edit" "count price value delete";
}

#Document-Details #document-positions .document-position .position-description {
    grid-area: description;
    font-weight: bold;
}

#Document-Details #document-positions .document-position .position-count {
    grid-area: count;
}

#Document-Details #document-positions .document-position .position-price {
    grid-area: price;
}

#Document-Details #document-positions .document-position .position-value {
    grid-area: value;
}

#Document-Details #document-positions .document-position #edit-position-button {
    grid-area: edit;
}

#Document-Details #document-positions .document-position #delete-position-button {
    grid-area: delete;
}

#Document-Details #document-positions .icon-button {
    color: var(--defaultForeground);
}

#Document-Details #document-positions .document-position button {
    align-self: center;
    justify-self: center;
}

#Document-Details #document-positions .document-position-edit {
    max-width: none;
    display: grid;
    grid-template-rows: max-content repeat(2, max-content) max-content;
}

#Document-Details #document-positions .document-position-edit .meta-data-input {
    max-width: none;
}

@media (max-width: 1020px) {
    #Document-Details #document-positions .document-position {
        display: grid;
        grid-template-rows: 30px 30px 30px 30px 30px;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "description description" "count count" "price price" "value value" "edit delete";
    }
}