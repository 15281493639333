#Login-Page {
    display: grid;
    background-color: var(--defaultBackground);
    color: var(--defaultForeground);
    height: 100%;
}

#Login-Page #error-message {
    color: var(--failure);
    font-size: 1.4em;
    font-style: bold;
    margin: auto;
    margin-top: 0;
}

#Login-Page #Login-Area {
    display: grid;
    margin: auto;
    margin-top: 5%;
    background-color: var(--contrastBackgroundDark);
    color: var(--contrastForegroundDark);
    height: 280px;
    width: 500px;
    border-radius: 25px;
    padding: 50px;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas: "ui" "pi" "b" "ms"
}

#Login-Page #user-input {
    width: 350px;
    margin: auto;
    grid-area: ui;
}

#Login-Page #password-input {
    width: 350px;
    margin: auto;
    grid-area: pi;
}

#Login-Page #login-button {
    width: 250px;
    margin: auto;
    grid-area: b;
}

#Login-Page #ms-button {
    width: 250px;
    margin: auto;
    grid-area: ms;
}

@media (max-width: 500px) {
    #Login-Page #Login-Area {
        height: min-content;
        width: 100%;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-areas: "ui" "pi" "b" "ms"
    }
    
    #Login-Page #user-input {
        width: calc(100% -20px);
    }
    
    #Login-Page #password-input {
        width: calc(100% -20px);
    }
    
    #Login-Page #login-button {
        width: calc(100% -20px);
    }
    
    #Login-Page #ms-button {
        width: calc(100% -20px);
    }
}