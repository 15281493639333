#Document-Details {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 40px;
    grid-template-areas: "tab" "buttons";
    margin-top: 10px;
}

#Document-Details #tab-control {
    grid-area: tab;
}

#Document-Details #tab-control .document-options .meta-data-form {
    grid-template-rows: repeat(4, 40px) 80px;
}

#Document-Details #tab-control .document-options .meta-data-property {
    grid-template-columns: 200px 1fr;
}

#Document-Details #tab-control .document-options #info-text {
    height: 80px;
}

#Document-Details #tab-control .document-options input {
    height: 30px;
    justify-self: left;
}

#Document-Details #buttons {
    grid-area: buttons;
}

@media (max-width: 520px) {
    #Document-Details #tab-control #document-options .meta-data-form {
        grid-template-rows: repeat(4, 80px) auto;
    }

    #Document-Details #tab-control #document-options .meta-data-property {
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content;
    }

    #Document-Details #tab-control #document-options #info-text {
        height: 80px;
    }
}