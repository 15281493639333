.tab-control {
    display: grid;
    grid-template-rows: 50px 1fr;
    grid-template-areas: "header""content";
    width: 100%;
    height: 100%;
    min-height: 0px;
    max-height: 100%;
    border-radius: 4px;
    border: 1px solid var(--borderColor);
}

.tab-control .tab-bar {
    background-color: var(--borderColor);
    color: var(--defaultForeground);
    grid-area: header;
    overflow: hidden;
    height: 50px;
}

.tab-control .tab-bar button {
    background-color: inherit;
    color: inherit;
    border-radius: 0px;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
    transition: 0.3s;
}

.tab-control .tab-bar button:hover {
    background-color: var(--highlightBackground);
    color: var(--highlightForeground);
}

.tab-control .tab-bar button.active {
    background-color: var(--highlightBackground);
    color: var(--highlightForeground);
}

.tab-control .tab-content {
    grid-area: content;
    width: 100%;
    height: 100%;
    min-height: 0px;
    max-height: 100%;
}

.tab-control .tab-item {
    max-width: 100%;
}

.tab-control .tab-item .meta-data-property .meta-data-input {
    max-width: 100%;
}
