.icon-button {
    height: 30px;
    width: 30px;
    margin: 0px;
    padding: 0px;
    background-color: transparent;
    color: var(--defaultForeground);
    border: none;
    border-radius: none;
    -webkit-appearance: none;
}

.icon-button:hover:enabled {
    background-color: transparent;
    color: var(--defaultForeground);
}

.icon-button:hover:enabled .icon {
    color: var(--highlightBackground);
}

.icon-button .icon {
    height: 30px;
    width: 30px;
}