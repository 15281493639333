#Dashboard {
    margin-top: 10px;
    display: grid;
    grid-template-rows: 500px auto;
    grid-template-areas: "stats""open";
}

#Dashboard #Statistics {
    margin: 5px;
    grid-area: stats;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 50px 1fr;
    grid-template-columns: 1fr 300px;
    grid-template-areas: "selection selection""chart numbers";
}

#Dashboard #Statistics #year-selection {
    grid-area: selection;
    justify-self: center;
    width: 300px;
    height: 32px;
}

#Dashboard #Statistics #chart {
    margin-top: 7px;
    display: grid;
    grid-area: chart;
    width: 100%;
    height: 100%;
    justify-items: center;
}

#Dashboard #Statistics #numbers {
    grid-area: numbers;
    width: 280px;
    height: 100%;
}

#Dashboard #Statistics #numbers .meta-data-property {
    display: grid;
    grid-template-columns: 180px 100px;
}

#Dashboard #Statistics #numbers .meta-data-property .meta-data-input {
    text-align: right;
}

#Dashboard #Open-Bills {
    grid-area: open;
    width: 100%;
}

#Dashboard #Open-Bills .bill-data {
    margin: 5px;
    display: grid;
    grid-template-rows: 30px 30px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "billnumber billdate billvalue" "billname billname billname";
    border-bottom: 1px solid var(--borderColor);
}

#Dashboard #Open-Bills .bill-data .bill-number {
    grid-area: billnumber;
}

#Dashboard #Open-Bills .bill-data .bill-name {
    grid-area: billname;
}

#Dashboard #Open-Bills .bill-data .bill-date {
    grid-area: billdate;
}

#Dashboard #Open-Bills .bill-data .bill-value {
    grid-area: billvalue;
}

@media (max-width: 1081px) {
    #Dashboard {
        grid-template-rows: 420px auto;
        margin: 10px;
    }

    #Dashboard #Statistics {
        grid-template-columns: 1fr 340px;
    }

    #Dashboard #Statistics #numbers {
        width: 340px;
    }
    
    #Dashboard #Statistics #numbers .meta-data-property {
        grid-template-columns: 220px 110px;
    }
}

@media (max-width: 889px) {
    #Dashboard {
        grid-template-rows: 600px auto;
        margin: 10px;
    }

    #Dashboard #Statistics {
        grid-template-rows: 50px 180px 310px;
        grid-template-columns: 1fr;
        grid-template-areas: "selection" "numbers" "chart";
    }

    #Dashboard #Statistics #numbers {
        justify-self: center;
        grid-area: numbers;
        width: 480px;
        height: 100%;
        margin-left: -20px;
    }
    
}