#Articles {
    margin-top: 10px;
}

#Articles #article-list .article-data {
    margin: 5px;
    display: grid;
    grid-template-rows: 30px;
    grid-template-columns: 1fr;
    grid-template-areas: "articlename" ;
}

#Articles #article-list .article-data .article-name {
    display: grid;
    grid-area: articlename;
    max-width: 100%;
}