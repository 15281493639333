.navbar .active{
    background-color: var(--highlightBackground);
}

.navbar .nav-dropdown {
    background-color: var(--defaultBackgroundComplementary);
    color: var(--defaultForegroundComplementary);
    border-color: var(--borderColor);
}

.navbar .nav-dropdown-item {
    background-color: var(--defaultBackgroundComplementary);
    color: var(--defaultForegroundComplementary);
}

.navbar .nav-dropdown-link{
    text-decoration: none;
    color: var(--defaultForegroundComplementary);
}

.dropdown {
    vertical-align: central;
    text-align: center;
}