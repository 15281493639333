:root {
  --defaultBackground: #F7F6F2;
  --contrastBackgroundDark: #EFECE5;
  --defaultBackgroundComplementary: #343a40;
  --defaultForeground: #343a40;
  --contrastForegroundDark: #343a40;
  --defaultForegroundComplementary: #F7F6F2;
  --highlightBackground: #2F80ED;
  --highlightForeground: #fff;
  --highlightOk: #27AE60;
  --highlightNotOk: #F70606;
  --borderColor: #999;
}

html, body, #root {
  /* Remove the FIREFOX and APPLE styles */
  -webkit-text-size-adjust: 100% !important;
  max-width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: system-ui;
  font-weight: 200;
  font-size: 16px;
  color: var(--defaultForeground);
  background-color: var(--defaultBackground);
  /* FIREFOX  */
  scrollbar-color: var(--defaultForeground) var(--defaultBackground);
}

*, *:before, *:after {
  box-sizing: inherit;
}

label {
  appearance: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: auto;
  margin-bottom: auto;
}

button {
  background-color: var(--defaultBackgroundComplementary);
  color: var(--defaultForegroundComplementary);
  border-radius: 4px;
  border: 1px solid var(--borderColor);
  outline: none;
  -webkit-appearance: none;
}

button:disabled {
  opacity: 0.5;
}

button:hover:enabled {
  background-color: var(--highlightBackground);
  color: var(--highlightForeground);
}

input {
  background-color: var(--defaultBackground);
  color: var(--defaultForeground);
  border-radius: 4px;
  border: 1px solid var(--borderColor);
  outline: none;
}

input[type=checkbox] {
  background: var(--defaultBackground);
}

input:focus {
  border-radius: 4px;
  border: 1px solid var(--highlightBackground);
}

select {
  -webkit-appearance: none;
  background-color: var(--defaultBackground);
  background-image: linear-gradient(45deg, transparent 50%, var(--defaultBackgroundComplementary) 50%), linear-gradient(135deg, var(--defaultBackgroundComplementary) 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) 0.8em, calc(100% - 15px) 0.8em, calc(100% - 2.5em);
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  color: var(--defaultForeground);
  border: 1px solid var(--borderColor);
  appearance: none;
  outline: none;
  border-radius: 3px;
  cursor: pointer;
}

select:focus {
  border-radius: 3px;
  border: 1px solid var(--highlightBackground);
}

.select-wrapper {
  /* ... */
  position: relative;
}

.select-wrapper::before {
  font-family: fontello;
  content: "\f107";
  position: absolute;
  right: 15px;
  top: 10px;
  color: #fff;
}

textarea {
  background-color: var(--defaultBackground);
  color: var(--defaultForeground);
  border-radius: 4px;
  border: 1px solid var(--borderColor);
}

textarea:focus {
  border-radius: 4px;
  border: 1px solid var(--highlightBackground);
  outline: none;
}

p {
  line-height: 1.5;
}

fieldset {
  margin: 8px;
  border: 1px solid silver;
  padding: 8px;
  border-radius: 4px;
  height: max-content;
}

legend {
  width: auto;
  padding-left: 5px;
  padding-right: 5px;
}

.app {
  display: grid;
  max-width: 100%;
  min-height: 100vh;
  grid-template-rows: auto 1fr;
  grid-template-areas: "n""e";
  overflow: hidden;
}

.mainComp {
  display: grid;
  grid-area: e;
  grid-template-areas: "mainComp";
  min-height: 100%;
  width: 1020px;
  margin: auto;
  grid-template-rows: auto 1fr;
  grid-template-areas: "toolbar""content";
}

.mainComp .tool-bar {
  justify-self: right;
  display: grid;
  grid-template-columns: repeat(auto-fill, 40px);
  column-gap: 2px;
  grid-area: toolbar;
  height: 40px;
  max-width: 200px;
  width: min-content;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--defaultForeground);
  border-bottom-left-radius: 6px;
}

.mainComp .tool-bar .icon-button {
  color: var(--defaultBackground);
  align-self: center;
  justify-self: center;
}

.meta-data-form {
  max-width: 100%;
  display: grid;
  grid-template-rows: repeat(auto-fill, 40px);
}

.meta-data-property {
  margin: 5px;
  display: grid;
  grid-template-columns: 150px 1fr;
}

.meta-data-property .meta-data-checkbox {
  width: 30px;
  height: 30px;
}

.element-container {
  border-bottom: 1px solid var(--borderColor);
}

.vertical-button-line {
  margin: 5px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 40px);
  direction: rtl;
}

.app .my-clickable {
  cursor: pointer;
}

@media (max-width: 1081px) {

  .mainComp {
    width: 100%;
    margin: 0px;
  }
}

@media (max-width: 520px) {

  .meta-data-form {
    grid-template-rows: repeat(auto-fit, 80px);
  }

  .meta-data-form .meta-data-property {
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content;
  }
}