#Company-Selection-Page{
    display: grid;
    background-color: var(--defaultBackground);
    color: var(--defaultForeground);
    height: 100%; 
}

#Company-Selection-Page #Company-Selection-Area {
    display: grid;
    margin: auto;
    margin-top: 5%;
    background-color: var(--contrastBackgroundDark);
    color: var(--contrastForegroundDark);
    height: 240px;
    width: 500px;
    border-radius: 25px;
    padding: 50px;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "s" "b"
}

#Company-Selection-Page #Company-Selection-Area #company-selection {
    width: 350px;
    margin: auto;
    grid-area: s;
}

#Company-Selection-Page #Company-Selection-Area #select-button {
    width: 250px;
    margin: auto;
    grid-area: b;
}